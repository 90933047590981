import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Menu.scss";
import "./Menu-Media.css";
import ServicesList from "../pages/ServicesArray";


function Menu() {

  const [open, setOpen] = useState(true)
  const [submenu1, setSubmenu1] = useState(false);

  const handleSubmenu1 = () => {
    setSubmenu1(!submenu1);
  };
  const handleSubmenu = () => {
    setOpen(true)
  }
  const handleSidemenu = () => {
    setSubmenu1(false)
    setOpen(true)
  }

  const handleHamburgerMenu = () => {
    setOpen(!open)
    setSubmenu1(false)
  }


  return (
    <>
      <div className="header">
        <nav className="nav-bar">
          <Link to="/" onClick={() => setOpen(true)}>
            <img
              className="logo-img"
              src="/images/navbar/Zeekable-logo.png"
              alt="logo"
            />
          </Link>
          <div className="menus">


            <Link to="/" className="menu">
              <div class="buttons">
                <button class="raise">Home</button>
              </div></Link>
            <Link to="/" className="menu">
              <div class="buttons">
                <button class="raise">About</button>
              </div></Link>
            <Link class="dropdown">
              <div class="dropbtn buttons">
                <button class="raise"> Services</button>
              </div>
              <div class="dropdown-content">
                {
                  ServicesList.map((service) => {
                    return (
                      <>
                        <Link to={"/services/" + service.link}><sapn className="bullet"> •  </sapn> {service.title}</Link>
                      </>
                    )
                  })
                }
              </div>
            </Link>
            <Link class="dropdown ">
              <div class="dropbtn buttons">
                <button class="raise buy-menu"> Buy</button>
              </div>
              <div class="dropdown-content">

                <Link to="/"><sapn className="bullet"> •  </sapn> Metaverse Land</Link>
                <Link to="/"><sapn className="bullet"> •  </sapn>The NFT's</Link>
              </div>
            </Link>

            <Link to="/contact" className="menu">
              <div class="buttons">
                <button class="raise">Contact</button>
              </div>
            </Link>
            {/* <Link to="/" className="menu" style={{ color: "rgb(255, 196, 0)" }}>
              <div class="buttons">
                <button class="raise">Enquire Now</button>
              </div></Link> */}
          </div>

          {
            open ? (
              <>
                <div class="hamburger-menu" onClick={handleHamburgerMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </>
            ) : (
              <>
                <div class="hamburger-close-menu" onClick={handleHamburgerMenu}>
                  <span></span>
                  <span></span>
                </div>
              </>
            )
          }
        </nav>

        {!open && (
          <div className="side-menu">

            <Link to="/" className="menu" onClick={handleSidemenu}>Home</Link>
            <Link to="/" className="menu" onClick={handleSidemenu}>About</Link>
            <Link onClick={handleSubmenu1}>
              Services {submenu1 ? <img className="down-arrow" src="/images/navbar/down.png"></img> : <img className="right-arrow" src="/images/navbar/right.png"></img>}

            </Link>
            {submenu1 && (

              <div className="sub-menu">
                {
                  ServicesList.map((service) => {
                    return (
                      <>
                        <Link to={"/services/" + service.link} onClick={handleSubmenu}>{service.title}</Link>
                      </>
                    )
                  })
                }
              </div>
            )}

            <Link to="/contact" className="menu" onClick={handleSidemenu}>Contact</Link>
            {/* <Link to="/register-complaint" className="menu" onClick={handleSidemenu}>Raise Complaint</Link> */}

          </div>

        )
        }

      </div>
    </>

  );
}

export default Menu;
