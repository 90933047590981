import React from "react";
import Form from "../components/Form/Form";
import "./RegisterComplaint.css"
import { Helmet } from "react-helmet";

function RegisterComplaint() {
    return (
        <>
            <Helmet>
            <title>Web 3 Company in Chennai, India | Blockchain, NFT & Metaverse Development Development Company in Chennai - Block2Verse. </title>
    
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="row m-0 main-container">
                <div className="col-lg-8">

                </div>
                <div className="col-lg-4 p-2 p-lg-5">
                    <div className="col-12">
                        <Form />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterComplaint;