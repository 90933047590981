import React from "react";
import "./Footer.css";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <section id="footer" className="footer">
                <div className="footer-sections">
                    <div>
                        <h6>About</h6>
                        <p>About</p>
                        <Link to="/contact"><p>Contact Us</p></Link>
                        <p>Products</p>
                    </div>
                    <div>
                        <h6>Services</h6>
                        <Link to="/services/metaverse"><p>Metaverse</p></Link>
                        <Link to="/services/augmented-reality"><p>Augmented Reality</p></Link>
                        <Link to="/services/virtual-reality"><p>Virtual Reality</p></Link>
                    </div>
                    <div>
                        <h6>Services</h6>
                        <Link to="/services/mixed-reality"> <p>Mixed Reality</p></Link>
                        <Link to="/services/blockchain"> <p>Blockchain</p></Link>
                        <Link to="/services/nft"> <p>NFT</p></Link>
                    </div>
                    <div>
                        <h6>Terms & Privacy</h6>
                        <p>Privacy Policy</p>
                        <p>Terms and Conditions</p>
                        <p>Disclaimer</p>
                    </div>
                </div>
                <br></br>
                <div className="icon-container">
                    <a target="_blank" href="https://www.facebook.com/Zeekable/"> <FaFacebook className="fa-facebook social-media-icon"></FaFacebook></a>
                    <a target="_blank" href="https://twitter.com/zeekable"><FaTwitter className="fa-twitter social-media-icon"></FaTwitter></a>
                    <a target="_blank" href="https://www.linkedin.com/company/zeekable"> <FaLinkedin className="fa-linkedin social-media-icon"></FaLinkedin></a>
                </div>
                <p className="d-none d-lg-block copy-right">Copyright ©2022 All rights reserved | Developed by <img className="footer-logo" src="/images/zeekable-fav-icon.png" alt="zeekable-logo"></img> <a target="blank" href="http://zeek.digital/" className="zeek-link">Zeek.Digital</a></p>
                <p className="d-block d-lg-none copy-right">Copyright ©2022 All rights reserved <br></br> Developed by <img className="footer-logo" src="/images/zeekable-fav-icon.png" alt="zeekable-logo"></img> <a target="blank" href="http://zeek.digital/" className="zeek-link">Zeek.Digital</a></p>
            </section>
        </>
    )
}

export default Footer;