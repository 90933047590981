import React, { useEffect, useState } from "react";
import "./Form.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

function Form() {

    const [value, setValue] = useState();

    useEffect(() => {
        loadCaptchaEnginge(6);
    })

    const success = () => toast.success('Thanks For Submitting', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const doesNotMatch = () => toast.error('Captcha does not match', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const doSubmit = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha) == true) {
            loadCaptchaEnginge(6);
            document.getElementById('user_captcha_input').value = "";
            success();
        }

        else {
            document.getElementById('user_captcha_input').value = "";
            doesNotMatch();
        }
    };

    return (
        <>

            <div className="contact-form form-glow">
                <form>
                    <h3 style={{ color: "white", textAlign: "center" }}>Enquire Now </h3>
                    <input
                        className="form-input"
                        name="name"
                        placeholder="Name"
                        type="text"
                        required
                    />
                    <input
                        className="form-input"
                        name="email"
                        placeholder="Email"
                        type="email"
                        required
                    />

                    <PhoneInput
                        className="form-phone-input"
                        country={'in'}
                        value={value}
                        onChange={setValue}
                        placeholder="Phone Number"
                        inputProps={{
                            name: 'phone',
                            required: true,
                        }}
                    />

                    <textarea
                        className="form-input"
                        name="message"
                        placeholder="Message"
                        type="text"
                        required
                    />

                    {/* <div class="login-box">
                        <a href="#" type="submit">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Submit
                        </a>
                    </div> */}

                    <div className="d-flex justify-content-between">
                        <div>
                            <input className="w-100" placeholder="Enter Captcha" id="user_captcha_input" name="user_captcha_input" type="text"></input>
                        </div>
                        <div className="captcha">
                            <LoadCanvasTemplate reloadText={`<img style="width: 15px; margin-bottom: 4px;" src="/images/refresh.png"></img> Reload Captcha `} />
                        </div>
                    </div>
                    {/* <div>
                        <div>
                            <canvas id="canv" width="150" height="30"></canvas>
                            <div>
                                <a id="reload_href" style="cursor: pointer; color: blue;">Reload Captcha</a>
                            </div>
                        </div>
                    </div> */}


                    <br></br>
                    <div className="container" onClick={doSubmit}>
                        <div className="btn"><a href="#" type="submit">Submit</a></div>
                    </div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                </form>

            </div>
        </>
    )
}

export default Form;