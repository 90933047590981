import React, { useState } from "react";
import "./Services.css";
import ServicesList from "./ServicesArray";
import { useParams } from "react-router-dom";
import AccordionFAQ from "../components/Accordion/Accordion";
import Form from "../components/Form/Form";
import { TypeAnimation } from 'react-type-animation';
import { Helmet } from "react-helmet";

function Services() {
    const id = useParams();

    return (
        <>
            <Helmet>
            <title>Metaverse Development Companies in Texas | NFT Development Company - Block2Verse. </title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="bg-black">
                {
                    ServicesList.map((service) => {
                        if (service.link === id.service)
                            return (
                                <>
                                    <div className="row m-0">
                                        <div className="col-lg-7 col-xl-8 details-container">
                                            <div className="col-12 details">
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex">
                                                        <h1>{service.title} For <br></br>
                                                            <TypeAnimation
                                                                // Same String at the start will only be typed once, initially
                                                                sequence={[
                                                                    'Education',
                                                                    1000,
                                                                    'Real Estate',
                                                                    1000,
                                                                    'Healthcare',
                                                                    1000,
                                                                    'Banking',
                                                                    1000,
                                                                    'Tourism',
                                                                    1000,
                                                                    'E-Shopping',
                                                                    1000,
                                                                    'Architects',
                                                                    1000,
                                                                    'Social Media',
                                                                    1000,
                                                                    'New Experience',
                                                                    1000,
                                                                ]}
                                                                speed={40} // Custom Speed from 1-99 - Default Speed: 40
                                                                wrapper="span" // Animation will be rendered as a <span>
                                                                repeat={Infinity} // Repeat this Animation Sequence infinitely
                                                            />
                                                        </h1>
                                                    </div>
                                                    <p>{service.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-4 p-3 p-lg-5">
                                            <div className="col-12">
                                                <Form />
                                            </div>
                                        </div>
                                    </div>
                                    <section id="brand" className="brand">
                                        <h1 className="heading">Top Brands We Do Service</h1>
                                        <br></br>
                                        <marquee width="90%" direction="left" height="auto">
                                            <div className="d-flex p-3">
                                                <div className="brand-box">
                                                    <img className="brand-img" src="/images/brand/meta-logo.png" alt="brand"></img>
                                                </div>
                                                <div className="brand-box">
                                                    <img className="brand-img" src="/images/brand/jio-tesseract-logo.png" alt="brand"></img>
                                                </div>
                                                <div className="brand-box">
                                                    <img className="brand-img" src="/images/brand/TheSandboxLogo.png" alt="brand"></img>
                                                </div>
                                            </div>
                                        </marquee>
                                        <br></br>
                                    </section>
                                    {/* <div className="row m-0 p-3 p-lg-5">
                                        <div className="col-lg-11 col-xl-7 faq-section">
                                            <h1>Frequently Asked Questions</h1>
                                            <br></br>
                                            <AccordionFAQ />
                                        </div>
                                    </div> */}

                                </>
                            )
                    })
                }
            </div>
        </>
    )
}

export default Services;