import React from "react";
import AccordionFAQ from "../components/Accordion/Accordion";
import Card from "../components/card/Card";
import "./Home.css"
import sample from "../video/pexels-shvets-production-7547023.mp4"
import ServicesList from './ServicesArray';
import { Link } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';
import { Helmet } from "react-helmet";

function Home() {
    return (
        <>
            <Helmet>
            <title>Web 3 Company in Texas | Blockchain, NFT & Metaverse Development Company in Houton, Dallas - Block2Verse. </title>
    
                <meta name="description" content="Looking for the development of Blockchain, NFT & Metaverse Products for your Business? Block2Verse provides complete Web-3 Solution for enabling Digital Transformation across globe from Texas, United States." />
            </Helmet>

            <div className="home">

                <section id="banner" className="banner row">
                    <div className="col-lg-6 banner-content">
                        <div className="banner-heading">
                            <TypeAnimation
                                sequence={['A Web3 - Metaverse Company', 1000, '']}
                                speed={40} // Must be in range between 1 and 99!
                                wrapper="h1"
                                repeat={Infinity}
                                cursor={false}
                            />
                        </div>
                        <p> Block2Verse (Blockchain to Metaverse) is a Future focussed Metaverse Company helping brands to launch their presence in Metaverse.  </p>
                    </div>
                    <div className="col-lg-6 p-0">
                        <video className='videoTag' autoPlay loop muted>
                            <source src={sample} type='video/mp4' />
                        </video>
                        {/* <Banner /> */}
                    </div>
                </section>

                <section id="brand" className="brand  d-lg-flex d-none">
                    <h1 className="heading">Brands We Inspire</h1>
                    <br></br>
                    <div className="d-flex p-3">
                        <div className="brand-box">
                            <img className="brand-img" src="/images/brand/meta-logo.png" alt="brand"></img>
                        </div>
                        <div className="brand-box">
                            <img className="brand-img" src="/images/brand/jio-tesseract-logo.png" alt="brand"></img>
                        </div>
                        <div className="brand-box">
                            <img className="brand-img" src="/images/brand/TheSandboxLogo.png" alt="brand"></img>
                        </div>
                    </div>
                    <br></br>
                </section>

                <section id="brand" className="brand d-flex d-lg-none">
                    <h1 className="heading">Brands We Inspire</h1>
                    <br></br>
                    <marquee width="90%" direction="left" height="auto">
                        <div className="d-flex p-3">
                            <div className="brand-box">
                                <img className="brand-img" src="/images/brand/meta-logo.png" alt="brand"></img>
                            </div>
                            <div className="brand-box">
                                <img className="brand-img" src="/images/brand/jio-tesseract-logo.png" alt="brand"></img>
                            </div>
                            <div className="brand-box">
                                <img className="brand-img" src="/images/brand/TheSandboxLogo.png" alt="brand"></img>
                            </div>
                        </div>
                    </marquee>
                    <br></br>
                </section>

                <section id="section2">
                    <img
                        className="bg-img-section2"
                        src="/images/card/hands-digital-universe-background.jpg"
                    ></img>
                    <h1 className="heading">Our Services</h1>
                    <div className="service-section">
                        {
                            ServicesList.map((service) => {
                                return (
                                    <>
                                        <Link to={"/services/" + service.link}>
                                            <Card img={service.image} title={service.title} />
                                        </Link>
                                    </>
                                )
                            })
                        }
                    </div>
                </section>

                {/* <section id="faq" className="faq">
                    <div className="row w-100 m-0 p-lg-5">
                        <div className="col-lg-11 col-xl-7 faq-section">
                            <h1>Frequently Asked Questions</h1>
                            <br></br>
                            <AccordionFAQ />
                        </div>
                    </div>
                </section> */}


            </div>
        </>
    )
}

export default Home;