import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./pages/Services";
import Menu from "./components/Menu";
import Contact from "./pages/Contact";
import Footer from "./components/Footer/Footer";
import RegisterComplaint from './pages/RegisterComplaint';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Menu />
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="services/:service" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/register-complaint" element={<RegisterComplaint />} />
          </Route>
        </Routes>
        <Footer />
        <section class="whats-app-btn">
          <a href="https://api.whatsapp.com/send?phone=+918946021376&text=Hi%20Sir,%20I'm%20Looking%20for%20more%20details%20about%20Block2verse"
            class="float" target="_blank">
            <WhatsAppIcon className="whatsapp-btn" />
          </a>
        </section>
      </BrowserRouter>
    </div>
  );
}

export default App;
