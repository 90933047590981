import React, { useState } from "react";
import "./Contact.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from "../components/Form/Form";
import { Helmet } from "react-helmet";


function Contact() {

    const [value, setValue] = useState();

    return (
        <>
            <Helmet>
            <title>Contact Block2Verse for Web 3 Development in Texas & India | Blockchain, NFT & Metaverse Development Development Company in Houston, Texas. </title>
    
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="contact">
                <div className="row m-0 ">

                    <div className="col-lg-7 col-xl-8 m-0 p-3 pb-5 p-lg-5">
                        <h1>Address</h1>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3270.4616724551956!2d80.22985106228053!3d12.891234781294257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525b183b831fd9%3A0x1cfe5e0818299f20!2sZeekable%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1663662145084!5m2!1sen!2sin"
                            width="100%"
                            height="90%"
                            style={{ border: "0px" }}
                            allowfullscreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                    <div className="col-lg-5 col-xl-4 p-3 p-lg-5">
                        <div className="col-12">
                            <Form />
                        </div>
                    </div>
                    {/* <section id="brand" className="brand">
                        <h1 className="heading">Top Brands We Do Service</h1>
                        <br></br>
                        <marquee width="90%" direction="left" height="auto">
                            <div className="d-flex p-3">
                                <div className="brand-box">
                                    <img className="brand-img" src="/images/brand/meta-company-in-chennai-inda.png" alt="brand"></img>
                                </div>
                                <div className="brand-box">
                                    <img className="brand-img" src="/images/brand/jio.svg" alt="brand"></img>
                                </div>
                                <div className="brand-box">
                                    <img className="brand-img" src="/images/brand/TheSandboxLogo.svg" alt="brand"></img>
                                </div>
                            </div>
                        </marquee>
                        <br></br>
                    </section> */}

                    {/* <button onClick={captchaNotMatch}>button</button> */}


                </div>

            </div>
        </>
    )
}

export default Contact;