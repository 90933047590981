const ServicesList = [
    {
        "id": 1,
        "title": "Metaverse",
        "content": "Most of the Companies are shifting to Metaverse Transformation. We can help you create and establish a Virtual world in Metaverse.",
        "image": "/images/card/metaverse.jpg",
        "link": "metaverse"
    },
    {
        "id": 2,
        "title": "Augmented Reality",
        "content": "Establish a awesome experience for your Business using AR Technology.",
        "image": "/images/card/augmented-reality.jpg",
        "link": "augmented-reality"
    },
    {
        "id": 3,
        "title": "Virtual Reality",
        "content": "Using VR Technology, you can impact your business, clients and transform the world.",
        "image": "/images/card/virtual-reality.jpg",
        "link": "virtual-reality"
    },
    {
        "id": 4,
        "title": "Mixed Reality",
        "content": "With the help of MR Technology, glasses are the future and anyone can live in Real & Virtual world to interact with peoples.",
        "image": "/images/card/mixed-reality.jpg",
        "link": "mixed-reality"
    },
    {
        "id": 5,
        "title": "Blockchain",
        "content": "Make your mark in emerging technology with decentralized, distributed ledger that stores the record of ownership of digital assets.",
        "image": "/images/card/blockchain-company-in-chennai-india.jpg",
        "link": "blockchain"
    },
    {
        "id": 6,
        "title": "NFT",
        "content": "Discover Digital masterpieces by established artists, animators & storytellers with the help of NFT Marketplaces & much more.",
        "image": "/images/card/nft-company-in-chennai-india.jpg",
        "link": "nft"
    }
]

export default ServicesList;