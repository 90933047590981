import React from "react";
import "./card.css";

function Card(props) {
    return (
        <>
            <div className="card-box glow">
                <img className="card-img" src={props.img} alt="metaverse"></img>
                <h4>{props.title}</h4>
                <p>The metaverse is a hypothetical iteration of the Internet</p>
                {/* <button class="btn">
                    <span class="btn-text">Learn more</span>
                </button> */}

                <h6 className="learn-more">
                    Learn more
                    <img className="right-arrow" src="/images/card/right-arrow.png"></img>
                </h6>
            </div>
        </>
    );
}

export default Card;